type classNameProp = string | Record<string, boolean> | undefined

export const classNames = (...classNames: classNameProp[]): string => {
  const resultClasses: string[] = []

  classNames.forEach(className => {
    if (className === undefined) {
      return
    }

    if (typeof className === 'string') {
      return resultClasses.push(className)
    }

    Object.keys(className).forEach(key => {
      if (className[key]) {
        resultClasses.push(key)
      }
    })
  })

  return resultClasses.join(' ')
}

export const removeWrapper = (htmlString: string) => {
  try {
    const wrapperTags = ['<p>', '</p>']
    const cleanedString = htmlString.replace(wrapperTags[0], '').replace(wrapperTags[1], '')
    return cleanedString
  } catch (error) {
    console.log(error)
    return htmlString
  }
}

export const truncateText = (text: string, length: number): string => {
  if (text.length <= length) return text
  return text.slice(0, length) + '...'
}

export const parseSpecialCharacters = (text = ''): string => {
  // This function replace special characters groups with their HTML entities
  // Example:  This is an example of __bold__ text -> This is an example os <b>bold</b> text
  /*
    Special characters:
    - ** -> <b></b>
    - __ -> <i></i>
    - ` -> <code></code>
    - ~ -> <del></del>
    - ^^ -> <sup></sup>
    - ,, -> <sub></sub>
    - global-linx-logo -> <GlobalLinxLogo />
    - [Texto del enlace](URL) -> <Link href="URL">Texto del enlace</Link>
  */
  const specialCharacters = [
    { regex: /(\*\*)(.*?)(\*\*)/g, replacement: '<b>$2</b>' },
    { regex: /(__)(.*?)(__)/g, replacement: '<i>$2</i>' },
    { regex: /(`)(.*?)(`)/g, replacement: '<code>$2</code>' },
    { regex: /(~)(.*?)(~)/g, replacement: '<del>$2</del>' },
    { regex: /(\^\^)(.*?)(\^\^)/g, replacement: '<sup>$2</sup>' },
    { regex: /(,,)(.*?)(,,)/g, replacement: '<sub>$2</sub>' },
    { regex: /(global-lynx-logo)/g, replacement: '<global-lynx-logo></global-lynx-logo>' },
    { regex: /\[(.*?)\]\((.*?)\)/g, replacement: '<intern-link href="$2">$1</intern-link>' }
  ]

  let parsedText = text
  specialCharacters.forEach(({ regex, replacement }) => {
    parsedText = parsedText.replace(regex, replacement)
  })

  return parsedText
}

export const formatArticleDate = (dateString: string): string => {
  // Format datetime string from "2024-05-13T23:47:29.168587-06:00" to "13 May 2024"
  const date = new Date(dateString)
  const dayTwoDigits = date.getDate().toString().padStart(2, '0')
  const month = date.toLocaleString('default', { month: 'short' })
  const year = date.getFullYear()

  return `${dayTwoDigits} ${month} ${year}`
}
