import React from 'react'
import { classNames } from '@/components/shared/classNames'

import styles from '@/components/forms/InputGroup.module.scss'

interface InputGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

const InputGroup = ({ className, ...props }: InputGroupProps) => {
  return (
    <div className={classNames(className, styles['input-group'])} style={props.style}>
      {props.children}
    </div>
  )
}

export default InputGroup
