import React, { ReactNode } from 'react'

import { classNames } from '@/components/shared/classNames'

import styles from './Label.module.scss'

type HtmlLabelProps = React.LabelHTMLAttributes<HTMLLabelElement>

export interface LabelProps extends HtmlLabelProps {
  children: string | ReactNode
  isValid?: boolean
  required?: boolean
  isFocused?: boolean
  hidden?: boolean
}

export const Label = ({
  isValid = false,
  required = false,
  isFocused = false,
  hidden = false,
  children,
  ...props
}: LabelProps) => {
  const isFocusedClass = isFocused ? styles['label--focused'] : ''
  const isValidClass = isValid ? '' : styles['label--invalid']
  const isHiddenClass = hidden ? '' : styles['label--hidden']
  const componentProps = {
    className: classNames(styles.label, isFocusedClass, isValidClass, isHiddenClass),
    ...props
  }

  return (
    <label {...componentProps}>
      {children}
      {required && <span className={styles['label--required']}>*</span>}
    </label>
  )
}
