/* Logo */
import isotipo from './logo/isotipo.svg'
import logoLarge from './logo/logo-large.svg'
import logoSmall from './logo/logo-small.svg'
import makingItBetter from './logo/making-it-better.svg'
import globalLynxBlog from './logo/global-lynx-blog.svg'
import globalLynxLetters from './logo/global-lynx.svg'
import globalLynxBlueLetters from './logo/global-lynx-blue.svg'

/* FontAwesome */
import anglesLeft from './fontawesome/angles-left.svg'
import anglesRight from './fontawesome/angles-right.svg'
import angleDown from './fontawesome/angle-down.svg'
import arrowLeft from './fontawesome/arrow-left.svg'
import arrowRight from './fontawesome/arrow-right.svg'
import arrowUpRight from './fontawesome/arrow-up-right.svg'
import briefcaseSolid from './fontawesome/briefcase-solid.svg'
import briefcase from './fontawesome/briefcase.svg'
import calendar from './fontawesome/calendar.svg'
import caretDown from './fontawesome/caret-down.svg'
import caretLeft from './fontawesome/caret-left.svg'
import caretRight from './fontawesome/caret-right.svg'
import caretUp from './fontawesome/caret-up.svg'
import certificate from './fontawesome/certificate.svg'
import certification from './fontawesome/certification.svg'
import chalkboardUser from './fontawesome/chalkboard-user.svg'
import check from './fontawesome/check.svg'
import checkBold from './fontawesome/check-bold.svg'
import chevronDown from './fontawesome/chevron-down.svg'
import chevronRight from './fontawesome/chevron-right.svg'
import chevronUp from './fontawesome/chevron-up.svg'
import chevronLeft from './fontawesome/chevron-left.svg'
import circle from './fontawesome/circle.svg'
import circleCheck from './fontawesome/circle-check.svg'
import circleUser from './fontawesome/circle-user.svg'
import clockRegular from './fontawesome/clock-regular.svg'
import clockRotateLeft from './fontawesome/clock-rotate-left.svg'
import close from './fontawesome/close.svg'
import copy from './fontawesome/copy.svg'
import diagramProject from './fontawesome/diagram-project.svg'
import download from './fontawesome/download.svg'
import email from './fontawesome/email.svg'
import eyeSlash from './fontawesome/eye-slash.svg'
import eye from './fontawesome/eye.svg'
import facebook from './fontawesome/facebook.svg'
import fecha from './fontawesome/fecha.svg'
import folder from './fontawesome/folder.svg'
import info from './fontawesome/info.svg'
import instagram from './fontawesome/instagram.svg'
import linkedin from './fontawesome/linkedin.svg'
import locationSolid from './fontawesome/location.svg'
import locationDot from './fontawesome/location-dot.svg'
import menu from './fontawesome/menu.svg'
import plus from './fontawesome/plus.svg'
import phoneSolid from './fontawesome/phone-solid.svg'
import quoteLeft from './fontawesome/quote-left.svg'
import search from './fontawesome/search.svg'
import share from './fontawesome/share.svg'
import speaker from './fontawesome/speaker.svg'
import star from './fontawesome/star.svg'
import tag from './fontawesome/tag.svg'
import twitter from './fontawesome/twitter.svg'
import user from './fontawesome/user.svg'
import youtube from './fontawesome/youtube.svg'
import whatsapp from './fontawesome/whatsapp.svg'

/* Simple Icons */
import bookmark from './simpleicons/bookmark-icon.svg'
import simpleCalendar from './simpleicons/calendar-icon.svg'
import clock from './simpleicons/clock-icon.svg'
import cookie from './simpleicons/cookie-icon.svg'
import location from './simpleicons/location-icon.svg'
import mail from './simpleicons/mail-icon.svg'
import phone from './simpleicons/phone-icon.svg'
import sandclock from './simpleicons/sandclock-icon.svg'
import world from './simpleicons/world-icon.svg'

/* Cards Icons */
import agile from './cards/agile-icon.svg'
import architecture from './cards/architecture-icon.svg'
import arquitectura from './cards/arquitectura-icon.svg'
import audit from './cards/audit-icon.svg'
import business from './cards/business-icon.svg'
import cert from './cards/cert-icon.svg'
import cibersecurity from './cards/cibersecurity-icon.svg'
import cloud from './cards/cloud-icon.svg'
import combos from './cards/combos-icon.svg'
import cons from './cards/cons-icon.svg'
import continuity from './cards/continuity-icon.svg'
import courses from './cards/courses-icon.svg'
import development from './cards/development-icon.svg'
import devops from './cards/devops-icon.svg'
import duration from './cards/duration-icon.svg'
import include from './cards/include-icon.svg'
import management from './cards/management-icon.svg'
import modality from './cards/modality-icon.svg'
import online from './cards/online-icon.svg'
import organization from './cards/organization-icon.svg'
import person from './cards/person-icon.svg'
import pipeline from './cards/pipeline-icon.svg'
import planning from './cards/planning-icon.svg'
import practices from './cards/practices-icon.svg'
import process from './cards/process-icon.svg'
import regulation from './cards/regulation-icon.svg'
import risk from './cards/risk-icon.svg'
import roadmap from './cards/roadmap-icon.svg'
import security from './cards/security-icon.svg'
import technology from './cards/technology-icon.svg'
import timing from './cards/timing-icon.svg'

/* Blue icons */
import blueBia from './blueicons/analisis-bia-icon.svg'
import blueRisk from './blueicons/analisis-riesgos.svg'
import blueIso from './blueicons/analisis-iso-icon.svg'
import blueAudit from './blueicons/auditoria-icon.svg'
import blueAuditIso from './blueicons/audit-iso-icon.svg'
import blueDatabase from './blueicons/base-de-datos-icon.svg'
import blueBcp from './blueicons/bcp-icon.svg'
import blueBiaIcon from './blueicons/bia-icon.svg'
import blueCompass from './blueicons/brujula-icon.svg'
import blueOrganizational from './blueicons/cambio-organizacional-icon.svg'
import blueTraining from './blueicons/capacitacion-icon.svg'
import blueTrainingTwo from './blueicons/capacitacion-dos-icon.svg'
import blueCertification from './blueicons/certificacion-icon.svg'
import blueControls from './blueicons/controles-icon.svg'
import blueDevops from './blueicons/devops-icon.svg'
import blueDevsecops from './blueicons/devsecops-icon.svg'
import blueDrp from './blueicons/drp-icon.svg'
import blueStrategy from './blueicons/estrategia-icon.svg'
import blueEvaluation from './blueicons/evaluacion-icon.svg'
import blueEvaluationTwo from './blueicons/evaluacion-dos-icon.svg'
import blueEvaluationMadurez from './blueicons/evaluacion-madurez-icon.svg'
import blueEvaluationDevops from './blueicons/evaluacion-devops-icon.svg'
import blueRiskEvaluation from './blueicons/evaluacion-riesgos-icon.svg'
import blueIdentify from './blueicons/identificar-icon.svg'
import blueImplementation from './blueicons/implementacion-icon.svg'
import blueImplementationTwo from './blueicons/implementacion-dos-icon.svg'
import blueImplementationIso from './blueicons/implementacion-iso-icon.svg'
import blueImplementationDevops from './blueicons/implementacion-devops-icon.svg'
import blueModel from './blueicons/modelo-icon.svg'
import blueModelRisk from './blueicons/modelo-riesgos-icon.svg'
import blueOrganization from './blueicons/organizacion-icon.svg'
import bluePlans from './blueicons/planes-icon.svg'
import bluePolitics from './blueicons/politicas-icon.svg'
import blueProofs from './blueicons/pruebas-icon.svg'
import blueQuotes from './blueicons/quotes-icon.svg'
import blueRecomendations from './blueicons/recomendaciones-icon.svg'
import blueRiskBusiness from './blueicons/riesgos-negocio-icon.svg'

export {
  /* Logo */
  isotipo,
  logoLarge,
  logoSmall,
  makingItBetter,
  globalLynxBlog,
  globalLynxLetters,
  globalLynxBlueLetters,
  /* FontAwesome */
  anglesLeft,
  anglesRight,
  angleDown,
  arrowLeft,
  arrowRight,
  arrowUpRight,
  briefcaseSolid,
  briefcase,
  calendar,
  caretDown,
  caretLeft,
  caretRight,
  caretUp,
  certificate,
  certification,
  chalkboardUser,
  check,
  checkBold,
  chevronDown,
  chevronRight,
  chevronUp,
  chevronLeft,
  circle,
  circleCheck,
  circleUser,
  clockRegular,
  clockRotateLeft,
  close,
  copy,
  diagramProject,
  download,
  email,
  eyeSlash,
  eye,
  facebook,
  fecha,
  folder,
  info,
  instagram,
  linkedin,
  locationSolid,
  menu,
  plus,
  phoneSolid,
  quoteLeft,
  search,
  share,
  speaker,
  star,
  tag,
  twitter,
  user,
  youtube,
  whatsapp,
  /* Simple Icons */
  bookmark,
  simpleCalendar,
  clock,
  cookie,
  location,
  locationDot,
  mail,
  phone,
  sandclock,
  world,
  /* Cards Icons */
  agile,
  architecture,
  arquitectura,
  audit,
  business,
  cert,
  cibersecurity,
  cloud,
  combos,
  cons,
  continuity,
  courses,
  development,
  devops,
  duration,
  include,
  management,
  modality,
  online,
  organization,
  person,
  pipeline,
  planning,
  practices,
  process,
  regulation,
  risk,
  roadmap,
  security,
  technology,
  timing,
  /* Blue Icons */
  blueBia,
  blueBiaIcon,
  blueRisk,
  blueIso,
  blueAudit,
  blueAuditIso,
  blueDatabase,
  blueCompass,
  blueBcp,
  blueTraining,
  blueTrainingTwo,
  blueCertification,
  blueControls,
  blueDevops,
  blueDevsecops,
  blueDrp,
  blueStrategy,
  blueEvaluation,
  blueEvaluationDevops,
  blueEvaluationMadurez,
  blueEvaluationTwo,
  blueRiskEvaluation,
  blueIdentify,
  blueImplementation,
  blueImplementationDevops,
  blueImplementationTwo,
  blueImplementationIso,
  blueModel,
  blueModelRisk,
  blueOrganization,
  blueOrganizational,
  bluePlans,
  bluePolitics,
  blueProofs,
  blueQuotes,
  blueRecomendations,
  blueRiskBusiness
}
